@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	body {
		@apply pt-24;
	}
}

section::-webkit-scrollbar {
	height: 0.8rem;
}
section::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(117, 116, 116, 0.3);
	background-color: #8d8985;
	border-radius: 4px;
}
section::-webkit-scrollbar-thumb {
	background-color: #53504d;
	outline: none;
	border-radius: 4px;
}
.node text {
	position: relative;
	z-index: 55;
	font-weight: 700;
}
path.link {
	fill: none !important;
	stroke: #6094a57b !important;
	stroke-width: 1px !important;
}
.node circle {
	fill: rgb(120, 217, 180) !important;
	stroke: rgb(11, 134, 124) !important;
	stroke-width: 1.5px !important;
}
.node {
	cursor: pointer;
}
